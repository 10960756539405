exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-sv-index-js": () => import("./../../../src/pages/sv/index.js" /* webpackChunkName: "component---src-pages-sv-index-js" */),
  "component---src-pages-widget-index-js": () => import("./../../../src/pages/widget/index.js" /* webpackChunkName: "component---src-pages-widget-index-js" */),
  "component---src-templates-channel-channel-template-js": () => import("./../../../src/templates/channel/channelTemplate.js" /* webpackChunkName: "component---src-templates-channel-channel-template-js" */),
  "component---src-templates-event-event-template-js": () => import("./../../../src/templates/event/eventTemplate.js" /* webpackChunkName: "component---src-templates-event-event-template-js" */),
  "component---src-templates-group-group-template-js": () => import("./../../../src/templates/group/groupTemplate.js" /* webpackChunkName: "component---src-templates-group-group-template-js" */),
  "component---src-templates-league-league-template-js": () => import("./../../../src/templates/league/leagueTemplate.js" /* webpackChunkName: "component---src-templates-league-league-template-js" */),
  "component---src-templates-sport-sport-template-js": () => import("./../../../src/templates/sport/sportTemplate.js" /* webpackChunkName: "component---src-templates-sport-sport-template-js" */),
  "component---src-templates-team-team-template-js": () => import("./../../../src/templates/team/teamTemplate.js" /* webpackChunkName: "component---src-templates-team-team-template-js" */)
}

